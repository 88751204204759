/* Popup.css */

body {
    font-family: 'Arial', sans-serif;
    background-color: #f9f9f9;
  }
  
  .app-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .popup-container {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    justify-content: center;
    align-items: center;
  }
  
  .popup {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    width: 90%;
    text-align: center;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
    color: black !important;
  }
  
  .popup p{
    text-align: left;
  }

  .popup h2 {
    margin-top: 0;
    color: #333;
  }
  
  .popup button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    margin-top: 10px;
  }
  
  .popup button:hover {
    background-color: #0056b3;
  }
  
  .show-popup-button {
    padding: 10px 20px;
    font-size: 16px;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background: none;
    text-decoration: underline;
  }
  