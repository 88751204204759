#News .box-f{
    color: rgb(77, 77, 77);
    line-height: 1.6;
}


.carousel .carousel-status {
    display: none;
}

.carousel .thumbs {
    display: none;
}

.new-line {
    white-space: pre-line;
}

.section-title h2 {
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
    padding-bottom: 6px;
    color: #000;
    letter-spacing: 0.3px;
    padding: 0;
}

.section-title::after {
    content: "";
    height: 3px;
    background: #fbb900;
    width: 80px;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
}

/****************/
/*	 BX-SLIDER 	*/
/****************/
section.client {
    padding: 4em 0em;
    background-color: #eee;

}

section.client .section-title {
    margin-bottom: 6em;
}

.bx-controls {
    position: relative;
}

.bx-wrapper .bx-pager {
    text-align: center;
    padding-top: 30px;
}

.bx-wrapper .bx-pager .bx-pager-item,
.bx-wrapper .bx-controls-auto .bx-controls-auto-item {
    display: inline-block;
    *zoom: 1;
    *display: inline;
}

.bx-wrapper .bx-pager.bx-default-pager a {
    background: #666;
    text-indent: -9999px;
    display: block;
    width: 10px;
    height: 10px;
    margin: 0 5px;
    outline: 0;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    border-radius: 5px;
}

.slick-slide {
    display: flex !important;
    justify-content: center;
}

.card{
    overflow: hidden;
    padding-bottom: 1rem;
    box-shadow: 0 1px 4px rgb(196, 193, 193);
    border: none !important;
    transition: transform 200ms ease-in;
}
.card:hover{
    transform: scale(1.02) !important;
}
.card-img-top{
    height: 10rem;
    width: 100%;
    object-fit: cover;
}
.modal-backdrop.show{
    opacity: 0.8 !important;
}

#News .img-fluid{
    display: flex;
    margin: auto;
}

.more-btn{
    display: inherit !important;
    width: 7rem !important;
    height: 2.5rem !important;
    padding: .5rem !important;
    font-family: inherit !important;
    font-weight: bold !important;
    font-size: 1rem !important;
    margin: 0 auto !important;
    border:none !important;
    color: rgb(100, 100, 100) !important;
    background-color: rgb(192, 192, 192) !important;
    transition: background 400ms ease-in, color 400ms ease-in !important;
    box-shadow: 0 0px 2px 0 rgba(0,0,0,0.16),0 0px 2px 0 rgba(0,0,0,0.12) !important;
}

#News .slick-slide{
    height: 400px !important;
}

.btn:hover{
    background-color: #8D8A8F !important;
    color: #eee !important;
}

#News .card-body{
    height: 14rem !important;
    overflow: hidden !important;
    padding: 1.5rem .9rem !important;
}

.news-card{
    height: 24rem;
}

#News .card-title{
    padding-top: 0 !important;
    height: fit-content !important;
    overflow: hidden;
    direction: rtl;
    font-size: larger !important;
    text-align: right;
}

#News .card-text{
    direction: rtl;
    overflow: hidden !important;
    margin-bottom: .75rem !important;
    
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;  
}

.modal-body h3{
    font-size: large;
    line-height: 1.8;
    font-weight: initial;
}

.modal-footer {
    justify-content: inherit;
}
.btn-close{
    position: absolute;
    top: 8px;
    right: 16px;
    padding: 1.5rem 0.5rem !important;
}
.modal-title{
    margin-right: 30px;
}

.news-card{
    padding-bottom: 0rem !important;
    margin: 0 auto;
}
