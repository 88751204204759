.nav-link {
    font-family: 'GE Hili Book' !important;
}

@font-face {
    font-family: 'GE Hili Book';
    src: url('../../assets/misc/GE\ Hili\ Book.otf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

.color-nav-lg {
    z-index: 999999;
    background-color: #8D8A8F;
}

.text-link {
    font-size: 19px;
    font-weight: 500;
    padding-left: 0rem !important;
    padding-right: 0rem !important;
    border-bottom: 3px solid transparent;
    color: #FFFFFF;
    text-align: center;
}

.logo-nav {
    padding: 0 !important;
    margin-top: -4px;
    margin-bottom: -2px;
}

.nayanlogo {
    height: 65px !important;
}

html {
    scroll-behavior: smooth;
}

.social:hover {
    pointer-events: auto !important;
    filter: invert(5%) brightness(1) sepia(15%) !important;
    transition: .2s !important;
    transform: scale(1.15) !important;
}

.nayanlogo {
    pointer-events: none;
}

.social {
    pointer-events: auto !important;
    filter: invert(10%) brightness(0.88) sepia(15%);
}

@media (max-width: 991px) {
    .text-link {
        border-bottom: 0px;
    }

    .text-link:hover {
        font-size: 19px;
        font-weight: 600;
        background-color: rgba(255, 255, 255, 0.1) !important;
        transition: none;
    }

    #responsive-navbar-nav {
        margin-top: .8rem;
        margin-left: -1rem;
        margin-right: -1rem;
    }

    .social-links {
        flex-direction: row !important;
        width: fit-content;
        margin-right: auto !important;
        margin-left: auto !important;
    }

    .social {
        margin-right: .5rem;
        margin-left: .5rem;
    }
}

@media (min-width: 992px) {
    .logo-nav {
        margin-right: .5rem !important;
    }

    

    .navbar {
        min-height: 5.2rem !important;
    }

    .navbar-expand-lg .navbar-nav {
        flex-direction: row;
        text-align: center;
        margin-right: auto;
        margin-left: auto;
    }

    .text-link {
        margin-left: .25rem;
        width: 6.5rem;
        color: #FFFFFF !important;
    }

    .text-link:hover {
        font-size: 19px;
        font-weight: 600;
        border-bottom: 3px solid #B46A55;
        transition: none;
        color: #FFFFFF !important;
    }

    .text-link.active {
        font-size: 19px;
        font-weight: 600;
        border-bottom: 3px solid #B46A55;
        transition: none;
        color: #FFFFFF !important;
    }

    .social-links {
        margin-left: .5rem !important;
    }
}
@media (max-width: 1200px) {

.nav-c {
    max-width: 1092px !important;
}}

@media (min-width: 1200px) {
    .logo-nav {
        margin-right: 1.5rem !important;
    }

    .container {
        padding: 0 !important;
    }

    .text-link {
        margin-left: 1rem;
        width: 8rem;
    }

    .navbar-expand-lg .navbar-nav {
        flex-direction: row;
        text-align: center;
    }

    .social-links {
        margin-left: 1.5rem !important;
    }
}

@media (min-width: 1400px) {
    .logo-nav {
        margin-right: 2.5rem !important;
    }

    .navbar-expand-lg .navbar-nav {
        flex-direction: row;
        text-align: center;
    }

    .social-links {
        margin-left: 2.5rem !important;
    }
}