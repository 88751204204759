.footer-bg {
    background-color:#4B4B4B;
    opacity: 0.87;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    padding-top: 33px;
    padding-bottom: 33px;
}

.footer-color {
    margin-top: 2rem;
    background-color: #3C3B3B;
    background-image: url(https://nayan.sa/temp/wp-content/uploads/2022/06/robin-spielmann-591331-unsplash.jpg);
    background-position: center right;
    background-repeat: no-repeat;
    background-size: cover;
}

.nayanlogo-footer {
    width: 10rem;
}

.list-inline {
    direction: ltr;
}

.btn-footer {
    border-radius:  50% !important;
    width: 55px;
    height: 55px;
    display: flex !important;
    justify-content: center;
    align-items: center;
}

.btn-footer:hover {
    background-color: #8D8A8F;
}

.social-footer {
    pointer-events: auto !important; 
    filter: invert(10%) brightness(0.88) sepia(15%);
    margin: auto;
    text-align: center;
}
.social-footer:hover {
    pointer-events: auto !important; 
    filter: invert(5%) brightness(1) sepia(15%);
    transition: .2s;
    transform: scale(1.15);
}
.address {
    background-color: rgba(0, 0, 0, 0.2);
    direction: ltr;

}

p.text-white {
    margin: 0 !important;
}