#AboutUs .box-f{
  color: rgb(77, 77, 77);
}

.fade-in-section {
  opacity: 0;
  transform: translateY(20vh);
  visibility: hidden;
  transition: opacity 0.5s ease-out, transform 0.4s ease-out;
  will-change: opacity, visibility;
}

.fade-in-section.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}

.graybg {
  background: repeat center bottom scroll;
}

h3 {
  margin-top: 2rem !important;
}

.col-lg-4 {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

@media (max-width: 991px) {
  h3 {
    margin-top: 3rem;
  }
}

@media (max-width:786px) {
  .graybg {
    background: no-repeat center bottom scroll none;
  }
}