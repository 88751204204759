.side-button{
  z-index: 10000;
  position: fixed !important;
  right: 10px !important;
  bottom: 20px !important;
  margin-top: .6rem !important;
  margin-left:  20px !important;
}
.buy-button{
  background-color: rgba(155, 25, 45, .8) !important; 
}
.rent-button{
  background-color: rgba(0, 178, 169, .8) !important; 
}

.whats-button{
  background: transparent !important;
  border: none !important;
  box-shadow: none !important;
}

.whats-button:hover {
  cursor: pointer;
  background: none !important; 
  transition: .2s !important;
  transform: scale(1.05);
}

.whats-button:visited {
  cursor: pointer;
  background: none !important; 
  transition: .2s !important;
  transform: scale(1.05);
}

.rent-button, .buy-button{
  width: 10rem !important;
  justify-self: center !important;
  height: 3rem !important;
  padding-top: .5rem !important;
  font-family: inherit !important;
  font-weight: 500 !important;
  font-size: 1.3rem !important;
  border: none !important;
  color: rgb(255, 255, 255) !important;
  /*8D8A8F*/
  line-height: 34px !important;
  border-radius: .50rem !important;
  float: center !important;
  transition: background 400ms ease-in, color 400ms ease-in !important;
  box-shadow: 0 0px 2px 0 rgba(0, 0, 0, 0.16), 0 0px 2px 0 rgba(0, 0, 0, 0.12) !important;
}

.buy-button:hover {
  cursor: pointer;
  background-color: rgba(155, 25, 45, 1) !important; 
  transition: .2s !important;
  transform: scale(1.05);
}

.rent-button:hover{
  cursor: pointer;
  transition: .5s;
  background-color: rgba(0, 178, 169, 1) !important; 
  transition: .2s !important;
  transform: scale(1.05);
}

@media (max-width:570px) {
  .rent-button, .buy-button{
    width: 8.4rem !important;
    height: 2.6rem !important;
    font-weight: 400 !important;
    font-size: 1.05rem !important;
}}