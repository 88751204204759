.carousel .carousel-status {
    display: none;
}

.carousel .thumbs {
    display: none;
}

.section-title h2 {
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
    padding-bottom: 6px;
    color: #000;
    letter-spacing: 0.3px;
    padding: 0;
}

.section-title::after {
    content: "";
    height: 3px;
    background: #fbb900;
    width: 80px;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
}

/****************/
/*	 BX-SLIDER 	*/
/****************/
section.client {
    padding: 4em 0em;
    background-color: #eee;

}

section.client .section-title {
    margin-bottom: 6em;
}

.bx-controls {
    position: relative;
}

.bx-wrapper .bx-pager {
    text-align: center;
    padding-top: 30px;
}

.bx-wrapper .bx-pager .bx-pager-item,
.bx-wrapper .bx-controls-auto .bx-controls-auto-item {
    display: inline-block;
    *zoom: 1;
    *display: inline;
}

.bx-wrapper .bx-pager.bx-default-pager a {
    background: #666;
    text-indent: -9999px;
    display: block;
    width: 10px;
    height: 10px;
    margin: 0 5px;
    outline: 0;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    border-radius: 5px;
}

.slick-slide {
    display: flex !important;
    justify-content: center;
}

.partner-logo {
    width: 160px;
    height: 160px;
    margin: auto;
}

@media (max-width: 991px) {
    .partner-logo {
        width: 120px;
        height: 120px;
    }
}