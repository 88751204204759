.loader{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.loader-img{
    width: 17rem;
    opacity: 100%;
}
.Centerr{
justify-content: center !important;
}
.stw{
    font-size: 2rem;
    font-weight: 500;
    font-family: 'Aref Ruqaa', serif;
    color: #fff;
    text-align: center;
    margin-bottom: 15px;
  }
  @media (max-width:570px) {
    .stw{
        font-size: 1.4rem;
        font-weight: 500;
        color: #fff;
        text-align: center;
        margin-bottom: 10px;
      }
      .loader-img{
        width: 12rem;
        opacity: 100%;
    } 
  }