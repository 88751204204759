.carousel .carousel-control-next-icon {
    width: 77px;
    height: 77px;
}

.carousel-inner {
    direction: ltr !important; /* Ensure it's always LTR */
  }
  
  .carousel-item {
    direction: ltr; /* Ensure individual slides are LTR */
  }
  
.carousel .carousel-control-prev-icon {
    width: 77px;
    height: 77px;
}

.active.carousel-item-right.carousel-item-next:not(.carousel-item-left) {
    -webkit-transform: translateX(100%);
    transform: translateX(100%)
}

.active.carousel-item-left.carousel-item-prev:not(.carousel-item-right) {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%)
}

.carousel-item {
    margin-top: 8vh;
    height: 92vh;
    background: no-repeat center center scroll;
    background-size: cover;
}

@media (max-width: 991px) {
    .carousel-item {
        margin-top: 7vh;
        height: 93vh !important;
        background-size: cover;
    }
}

@media (min-width: 768px) {
    .carousel-vid {
        display: none !important;       
    }
    .carousel-nonvid {
        display: block !important;
        height: 92vh;
        width: 100% !important;
        background-size: cover;
      }
  }
  @media (max-width: 768px) {
    .carousel-vid {
        display: block !important;
        height: 93vh;
        width: 100% !important;
        background-size: cover;
    }

    .carousel-nonvid {
        display: none !important;
        width: 100%;
        height: 92vh;
      }
  }