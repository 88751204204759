* {
    font-family: 'Amiri'; 
}

@font-face {
    font-family: 'AL-Mohanad';
    src: url('./assets/misc/AL-Mohanad.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'AL-Mohanad';
    src: url('./assets/misc/AL-Mohanad Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'AL-Mohanad';
    src: url('./assets/misc/AL-Mohanad Extra Bold.ttf') format('truetype');
    font-weight: bolder;
    font-style: normal;
}

html {
    scroll-padding-top: 96px;
}

@media (max-width: 992px) {
    html {
        scroll-padding-top: 76px;
    }
}

/* Sections Style */
.content-section-heading {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
}

.content-section-heading h1 {
    font-weight: 500;
    color: #8D8A8F;
    font-family: 'GE Hili Book' !important;
}

body section:nth-of-type(3n) {
    background-color: rgb(128, 121, 119, 0.2);
}

body section {
    min-height: 400px;
}

section {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
}

.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #8D8A8F;
    opacity: 80%;
    animation: fadeOutAnimation ease 10s;
}

img {
    pointer-events: none;
}

.fade-list-item {
    opacity: 0;
    animation-name: fadein;
    animation-duration: 500ms;
    animation-iteration-count: 1;
    animation-name: fadein;
    animation-duration: 500ms;
    animation-iteration-count: 1;
    animation-fill-mode: backwards;
}

@keyframes fadein {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}