.carousel .carousel-status {
   display: none;
}

.carousel .thumbs {
   display: none;
}

#map {
   width: 100% !important;
   height: 50vh;
}

.status {
   width: 300px;
   height: 300px;
   overflow: hidden;
   position: absolute;
}

.status span {
   position: absolute;
   display: block;
   width: 450px;
   padding: 15px 0;
   box-shadow: 0 5px 10px rgba(0,0,0,.1);
   color: #fff;
   font: 700 18px/1 'Lato', sans-serif;
   text-shadow: 0 1px 1px rgba(0,0,0,.2);
   text-transform: uppercase;
   text-align: center;
   right: -25px;
   top: 30px;
   transform: rotate(-45deg);
   padding-left: 32%;
   padding-right: 52%;
 }

.countdown-table{
   margin: auto;
   table-layout: fixed;
   width: 100%;
}

.countdown-table td{
   text-align: center;
   font-size: .8rem !important;
   font-weight: 500 !important;
   padding: 0 !important;
}

.available span{
   background: rgba(17, 114, 62, 0.8);
}

.availablerent span{
   background: rgba(157, 160, 15, 0.8);
}

.notavailablerent span{
   background: rgba(166, 25, 46, .8);
}

.sold span{
   background: rgba(166, 25, 46, .8);
}

.soon span{
   background: rgba(180, 106, 85, .8);
}

.infowindow-content {
   padding: .75rem
}

.infowindow-content .title {
   font-size: 1.5rem;
   font-weight: 500;
   text-decoration: none !important;
   color: #8D8A8F;
}

#legend {
   width: 45%;
   height: 17% !important;
   border-radius: 15px 10px 0px 0px;
   background-color: rgba(187, 181, 168, 0.8);
   font-size: 1.3rem;
   font-weight: 500;
   color: rgb(46, 46, 46);
   padding-top: 1.2% !important;
}

.row-legend {
   margin-right: 20px !important;
}

.img-legend {
   height: 38px;
   margin-left: 13px;
}


@media (max-width: 991px) {
   .countdown-table td{
      text-align: center;
      font-size: .7rem !important;
      font-weight: 400 !important;
      padding: 0 !important;
   }

   #legend {
      width: 68%;
      height: 27% !important;
      border-radius: 10px 10px 0px 0px;
      padding-top: 2% !important;
   }

   .row-legend {
      margin: 0px !important;
   }

   .img-legend {
      margin-left: 0px;
      margin: auto;
      display: flex;
   }

   #legend .col-4{
      padding-left: 1px !important;
      padding-right: 1px !important;
   }

   #legend span>div{
      padding-top: 4px !important;
      padding-bottom: 4px !important;
      display: block !important;
      font-size: 1.1rem !important;
      text-align: center !important;
   }
}

.project-card {
   height: 360px !important;
   margin-left: 1rem !important;
   margin-right: 1rem !important;
   margin-bottom: 1rem !important;
   text-decoration: none !important;
}

.card-title {
   font-size: 1.7rem !important;
   font-weight: 700 !important;
   height: 3rem !important;
   color: #8D8A8F;
   padding-right: auto;
   text-align: center;
   padding-top: 1rem !important;
   margin-top: 0rem !important;
}

.card-body {
   padding: 0 !important;
}

.project-card>img {
   height: 225px !important;
}


.slick-slide>div {
   width: 100% !important;
}

.slick-next{
   z-index: 99999 !important;
   right: -30px !important;
   width: 36px;
}

.slick-prev{
   z-index: 99999 !important;
   left: -30px !important;
   width: 36px;
}


#Apartments .slick-next{
   right: 15px !important;
}

#Apartments .slick-prev{
   left: 15px !important;
}

.slick-next::before{
   content: url("../../assets/icons/next.svg") !important;
   filter: invert(17%) sepia(12%) saturate(30%) hue-rotate(348deg) brightness(86%) contrast(104%);
}

.slick-prev::before{
   content: url("../../assets/icons/prev.svg") !important;
   filter: invert(17%) sepia(12%) saturate(30%) hue-rotate(348deg) brightness(86%) contrast(104%);
}
#Apartments .slick-next::before{
   filter: invert(0%) sepia(94%) saturate(0%) hue-rotate(204deg) brightness(100%) contrast(106%);
}

#Apartments .slick-prev::before{
   filter: invert(0%) sepia(94%) saturate(0%) hue-rotate(204deg) brightness(100%) contrast(106%);
}

.icon-feature {
   height: 32px !important;
   width: 32px !important;
   padding: 0 !important;
   margin-left: auto;
   margin-right: auto;
   filter: invert(51%) sepia(14%) saturate(144%) hue-rotate(327deg) brightness(90%) contrast(84%);
}

#legend span>div{
   display: contents;
}

.row-features {
   margin-left: 8px !important;
   margin-right: 8px !important;
   margin-top: 15px !important;
}

.row-features span {
   font-size: .9rem !important;
   color: #8D8A8F;
   font-weight: 700;
   text-align: center;
   padding-top: 8px;
   padding-left: 0;
   padding-right: 0;
}

@media (max-width: 991px) {
   .slick-next{
      z-index: 9999 !important;
      right: -10px !important;
      width: 36px;
   }
   
   .slick-prev{
      z-index: 9999 !important;
      left: -10px !important;
      width: 36px;
   }


.row-features span {
   font-size: .7rem !important;
}

#OurProducts .slick-slide{
   padding: 10px;
}

}