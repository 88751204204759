.rtl {
  direction: rtl;
}

.ltr {
  direction: ltr;
}

#Apartments .row {
  margin-left: auto !important;
  margin-right: auto !important;
}

.icon-feature-1 {
  height: 90% !important;
  width: 90% !important;
  padding: 0 !important;
  margin-left: auto;
  margin-right: auto;
  filter: invert(51%) sepia(14%) saturate(144%) hue-rotate(327deg) brightness(90%) contrast(84%);
}

.icon-feature-2 {
  width: 25% !important;
  padding: 0 !important;
  margin-left: auto;
  margin-right: auto;
  filter: invert(51%) sepia(14%) saturate(144%) hue-rotate(327deg) brightness(90%) contrast(84%);
}

.press {
  font-size: 0.9rem !important;
  font-weight: 500;
  color: #B46A55 !important;
  text-align: center;
}

.red {
  border-top: 3px solid hsl(0, 78%, 62%);
  ;
}

.ReactFlipCard__Front {
  background-color: rgba(241, 241, 241, .8) !important;
}

.ReactFlipCard,
.ReactFlipCard__Front,
.ReactFlipCard__Back,
.ReactFlipCard__Flipper {
  width: 100%;
  height: 100%;
}

.ReactFlipCard__Front,
.ReactFlipCard__Back {
  box-shadow: 0px 25px 20px -26px hsla(229, 6%, 66%, 0.466);
  transition: .7s;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding: 25px;
}

.ReactFlipCard__Front {
  background-color: rgb(253, 253, 253);
  border-top: 3px solid #8D8A8F;
}

.ReactFlipCard__Back {
  background-color: rgb(255, 255, 255);
  border-top: 3px solid #B46A55;
}

.header-pic .display-0 {
  font-size: 4rem !important;
  font-weight: 600 !important;
  line-height: 1.2 !important;
  color: #8D8A8F !important;
  text-shadow: 1px 3px 6px #edeaee !important;
}

.header-pic .display-2 {
  font-size: 2rem !important;
  font-weight: 700 !important;
  line-height: 1.2 !important;
  color: #6b605e !important;
  text-shadow: 2px 2px 4px #C6B599 !important;
}

.ReactFlipCard__Back {
  padding: 0 !important;
  font-size: 12px !important;
}

.bg-2909:before {
  background-image: url("../../assets/imgs/ProjectsPhotos/Nayan 2909/2909-6.jpg");
}

.bg-3299:before {
  background-image: url("../../assets/imgs/ProjectsPhotos/Nayan 3299/3299-6.jpg");
}

.bg-3569:before {
  background-image: url("../../assets/imgs/ProjectsPhotos/Nayan 3569/3569-10.jpg");
}

.bg-3839:before {
  background-image: url("../../assets/imgs/ProjectsPhotos/Nayan 3839/3839-5.jpg");
}

.bg-3832:before {
  background-image: url("../../assets/imgs/ProjectsPhotos/Nayan 3832/3832-2.jpg");
}

.bg-4845:before {
  background-image: url("../../assets/imgs/ProjectsPhotos/Nayan 4845/4845-0.jpg");
}

.bg-7119:before {
  background-image: url("../../assets/imgs/ProjectsPhotos/Nayan 7119/7119-0.jpg");
}

.bg-7580:before {
  background-image: url("../../assets/imgs/ProjectsPhotos/def.jpg");
}

.bg-3794:before {
  background-image: url("../../assets/imgs/ProjectsPhotos/Nayan\ 3794/3794-0.jpg");
}

.header-pic {
  margin-top: 0 !important;
  position: relative;
  overflow: hidden;
  height: 40vh;
  padding: 10vh;
  width: 100%;
}

.header-pic:before {
  content: ' ';
  display: block;
  position: absolute;
  padding: 3rem;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: .4;
  background-repeat: no-repeat;
  background-position: center;
  -ms-background-size: cover;
  -o-background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  background-size: cover;
}

.new-btn-3 {
  padding-left: 20px !important;
  padding-right: 20px !important;
  padding-top: 20px !important;
  justify-self: center !important;
  display: contents !important;
  align-items: center !important;
  justify-content: center !important;
  padding: .5rem !important;
  margin-left: 1rem !important;
  margin-right: 1rem !important;
  /*8D8A8F*/
  float: center !important;
}

.new-btn-1 {
  border-radius: 5px !important;
  border: none !important;
  width: 15rem !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
  padding-top: 20px !important;
  justify-self: center !important;
  height: 3rem !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  padding: .5rem !important;
  font-family: inherit !important;
  font-weight: bold !important;
  font-size: 1.2rem !important;
  margin-left: 1rem !important;
  margin-right: 1rem !important;
  color: rgb(255, 255, 255) !important;
  /*8D8A8F*/
  float: center !important;
  transition: background 400ms ease-in, color 400ms ease-in !important;
  box-shadow: 0 0px 2px 0 rgba(0, 0, 0, 0.16), 0 0px 2px 0 rgba(0, 0, 0, 0.12) !important;
}

.new-btn-1 img {
  margin-bottom: .2rem;
}

.prices {
  background-color: rgba(155, 25, 45, .8) !important;
}

.location {
  background-color: rgba(0, 178, 169, .8) !important;
}


.prices:hover {
  cursor: pointer;
  background-color: rgba(155, 25, 45, 1) !important;
  transition: .2s !important;
  transform: scale(1.05);
}

.location:hover {
  cursor: pointer;
  transition: .5s;
  background-color: rgba(0, 178, 169, 1) !important;
  transition: .2s !important;
  transform: scale(1.05);
}

.message-status {
  font-size: 1.1rem !important;
  font-weight: 400;
  color: #8D8A8F !important;
  text-align: center;
}

.new-Btn-2 {
  border-radius: 5px !important;
  width: 10rem !important;
  margin-top: .6rem !important;
  justify-self: center !important;
  height: 2.5rem !important;
  padding: .5rem !important;
  font-family: inherit !important;
  font-weight: bold !important;
  font-size: 1rem !important;
  border: none !important;
  color: rgb(255, 255, 255) !important;
  /*8D8A8F*/
  background-color: #8D8A8F !important;
  float: center !important;
  transition: background 400ms ease-in, color 400ms ease-in !important;
  box-shadow: 0 0px 2px 0 rgba(0, 0, 0, 0.16), 0 0px 2px 0 rgba(0, 0, 0, 0.12) !important;
}

.height1 {
  height: 400px !important;
  padding-bottom: calc(var(--bs-gutter-x)) !important;
}

.height2 {
  height: 200px !important;
  padding-bottom: calc(var(--bs-gutter-x)) !important;
}

.big-title {
  font-size: 38px;
  font-weight: 500;
  color: #706967;
  text-align: center;
}

a:link {
  text-decoration: none;
}

.st {
  font-size: 1.1rem !important;
  font-weight: 500;
  color: #B46A55 !important;
  text-align: center;
}

.btn-icon {
  width: 25px !important;
  margin-right: 10px;
}

.feature-title {
  font-size: 1.5rem;
  font-weight: 500;
  color: #706967;
  text-align: center;
}

.center-1 {
  justify-content: center !important;
}

.row-border {
  /* border: #706967 2px solid; */
  justify-content: center;
  margin-left: 8px !important;
  margin-right: 8px !important;
  margin-top: 5px !important;
}

.form-control.line {
  display: inline;
  margin-left: 1rem;
  margin-bottom: 1rem;
}

.form-control {
  height: 52px !important;
  background: #fff !important;
  color: #495057;
  font-size: 18px;
  border-radius: 5px;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.icon-card {
  justify-content: center;
}

#Apartments .slick-slide img {
  height: 300px;
  margin: auto;
  border: white;
  border-style: solid;
}

.form-control-none {
  display: none;
}

.o-fit{
  height: 100% !important;
  width: 100% !important;
}

@media (min-width: 992px) {
  #Apartments {
    margin-top: 3rem !important;
  }

  .middle-layer {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
}

@media (max-width: 992px) {
  #Apartments {
    margin-top: 2.5rem !important;
  }

  .middle-layer {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .big-title {
    font-size: 33px;
  }

  .header-pic .display-0 {
    font-size: 2.4rem !important;
  }

  .height1 {
    height: 500px !important;
  }

  .header-pic {
    height: 50vh;
    padding: 22vh;
  }

  .pl-0 {
    padding-left: calc(var(--bs-gutter-x) * .5) !important;
  }

  .pr-0 {
    padding-right: calc(var(--bs-gutter-x) * .5) !important;
  }
}


@media (max-width:768px) {
  .new-Btn {
    width: 12rem !important;
  }

  #Apartments .slick-slide img {
    height: 100%;
    margin: auto;
  }

  .gal {
    margin-right: calc(-2 * var(--bs-gutter-x)) !important;
    margin-left: calc(-2 * var(--bs-gutter-x)) !important;
  }

  .slick-slider {
    padding: 0 !important;
  }

  .height1 {
    height: 500px !important;
  }

  .height2 {
    height: 400px !important;
    padding: 0 !important;
  }

  .height2 .col-sm-12 {
    padding-bottom: 25px !important;
  }

  .height3 {
    height: 200px !important;
    padding-bottom: calc(var(--bs-gutter-x)) !important;
  }

  .pr-0 {
    padding-right: var(--bs-gutter-x, .75rem) !important;
    padding-left: var(--bs-gutter-x, .75rem) !important;
  }

  .pl-0 {
    padding: 0 !important;
  }

  .ReactFlipCard .container {
    padding-left: 0;
    padding-right: 0;
  }

  .ReactFlipCard .contastiner {
    padding-left: 0;
  }

  #Apartments.row-features-details span {
    font-size: .7rem !important;
    color: #8D8A8F;
    font-weight: 700;
    text-align: center;
    padding-top: 8px;
    padding-left: 0;
    padding-right: 0;
  }

  .container-1 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .disappear {
    width: 100%;
  }

  .press {
    font-size: 0.8rem !important;
    width: 110%;
  }

  .new-Btn-2 {
    width: 8rem !important;
  }

  .new-btn-3 {
    display: flex !important;
  }
}

@media (max-width:1200px) {
  .disappear {
    width: none;
  }

  .pr-0 .container,
  .pl-0 .container {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}